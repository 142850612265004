import React from 'react'
import style from 'styled-components'
import { Link } from 'gatsby'

const StyledLink = style(Link)`
  margin: 1%;
`

const StyledButton = style.button`
  color: white;
  background: rgba(0, 0, 0, 0);
  border: 0.15rem solid white;
  border-radius: 0.5rem;
  border-spacing: 1rem 1rem;
  min-width: 12rem;
  width: auto;
  height: 2rem;
  font-size: 1rem;
  margin: 2% auto 2%;
  align-self: flex-start;

  :hover {
    background: white;
    color: transparent;
    transition: ease 200ms;
  }
`

const Inner = style.div`
  background: transparent;

  ${StyledLink}:hover & {
    background: linear-gradient(to right, orange, yellow, green, cyan, blue, violet);
    /* Standard syntax (must be last) */
    background-clip: text;
    background-size: 300%;
    animation: flow 3s ease-in-out infinite;
    /* TODO: Workaround for WebKit based browsers. This should be handled automatically */
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  @keyframes flow {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
`

const RainbowButton = ({ to, children }) => (
  <StyledLink to={to}>
    <StyledButton>
      <Inner>{children}</Inner>
    </StyledButton>
  </StyledLink>
)

export default RainbowButton
