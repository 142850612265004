import React from 'react'
import style, { css } from 'styled-components'

import MailchimpSubscribe from 'react-mailchimp-subscribe'

const url =
  'https://gmail.us20.list-manage.com/subscribe/post?u=4af65a293badae36b2c1a6a49&amp;id=7c416dce7d'

const FormContainer = style.div`
  background: transparent;
  border-radius: .5em;
  border: .15em solid white;
  display: flex;
  flex-direction: row;
  @media (max-width: 560px) {
    flex-direction: column;
  }
`
const General = css`
  font-size: 100%;
  color: white;
  background: #ffffff40;
  border: none;
  text-align: center;
  padding: 0.25em;
  justify-content: center;
  margin: 0;
  ::placeholder {
    color: slategrey;
  }
`
// const NameInput = style.input`
//   ${General}
// `
const MailInput = style.input`
  ${General}
`
const SubmitButton = style.button`
  ${General}
  background: transparent;
  color: white;
  border-top: .15em solid white;
  :hover {
    background: white;
    color: slategray;
  }
  @media (min-width: 560px) {
    border-top: none;
    border-left: .15em solid white;
  }
`
const StatusPopup = style.div`
  ${General};
  border-bottom: .15em solid white;
  color: ${props => props.color};
  animation: hide 0s ease-in 4s forwards;
  animation-fill-mode: forwards;
  @keyframes hide {
    to {
      width: 0;
      height: 0;
      overflow: hidden;
      visibility: hidden;
      position: absolute;
    }
  }
  @media (min-width: 560px) {
    border-bottom: none;
    border-right: .15em solid white;
  }
`

const CustomForm = ({ status, message, onValidated }) => {
  const statusText = {
    success: 'Iscritto! Controlla la posta',
    sending: 'Iscrizione...',
    exists: 'Indirizzo già iscritto',
    error: "Ops... c'è stato un errore"
  }
  let email
  // let name
  const submit = () =>
    email &&
    // name &&
    email.value.indexOf('@') > -1 &&
    onValidated({
      EMAIL: email.value
      //   NAME: name.value
    })
  return (
    <FormContainer>
      {status === 'sending' && (
        <StatusPopup color="yellow">{statusText.sending}</StatusPopup>
      )}
      {status === 'error' && (
        <StatusPopup color="crimson">{statusText.error}</StatusPopup>
      )}
      {status === 'success' && (
        <StatusPopup color="lawngreen">{statusText.success}</StatusPopup>
      )}
      {/* <NameInput
          ref={node => (name = node)}
          type="text"
          placeholder="Your name"
        /> */}
      <MailInput
        ref={node => (email = node)}
        type="email"
        placeholder="Il tuo indirizzo email"
      />
      <SubmitButton onClick={submit}>
        Iscriviti alla nostra newsletter
      </SubmitButton>
    </FormContainer>
  )
}

// use the render prop and your custom form
const SubscribeNewsletter = () => (
  <MailchimpSubscribe
    url={url}
    render={({ subscribe, status, message }) => (
      <CustomForm
        status={status}
        message={message}
        onValidated={formData => subscribe(formData)}
      />
    )}
  />
)

export default SubscribeNewsletter
