import React from 'react'
import style from 'styled-components'

import SEO from '../components/seo'
import Layout, { navbarLinks } from '../components/layout'
import Logo from '../components/logo'
import Background from '../components/background'
import RainbowButton from '../components/rainbowbutton'
import Quote from '../components/quote'
import SubscribeNewsletter from '../components/subscribe'

const fullIntro = `Salve, siamo sette giovani amici che hanno deciso di dare vita ad un progetto molto importante, 
  ed è per questo che vogliamo raccontarvi la nostra storia. Tutto nasce fondamentalmente dalla profonda amicizia 
  che ci lega fin da quando eravamo bambini: proprio per questo abbiamo scelto di chiamarci “Amici di Andrea”. 
  Andrea invece è il coraggiosissimo amico,che oggi è diventato il nostro angelo custode. 
  Noi dunque, siamo un’associazione benefica che cerca di aiutare quei pazienti che vivono ogni giorno la sua 
  stessa situazione. In particolare infatti,collaboriamo con il reparto di Ematologia del Policlinico Umberto I, 
  al quale, grazie ai fondi che raccogliamo,forniamo cose all’apparenza molto semplici,ma in realtà utilissime ai 
  piccoli pazienti, parliamo ad esempio,di misuratori di pressione, braccioli pediatrici, termometri,tavolini da letto, 
  o addirittura libri usati,che consentono loro di studiare. Insomma, con dei semplici gesti, cerchiamo di migliorare 
  la loro vita in Ospedale. Non solo,quest’anno abbiamo anche organizzato degli eventi, come per esempio quelli del 
  16 Dicembre e del 14 Luglio,dove siamo riusciti a raccogliere dei fondi,i quali poi sono stati impegnati per comprare 
  ciò che l’Ospedale ci aveva richiesto.Detto ciò, noi crediamo molto in questa avventura che abbiamo deciso di 
  intraprendere e speriamo che anche voi che avete ascoltato questa storia, riusciate a capire quanta passione e quanto 
  impegno ci sia dietro. Un saluto da “Amici di Andrea”`

const StyledLayout = style(Layout)`
  --footer-text-color: white;
`

const homeNavbarLinks = JSON.parse(JSON.stringify(navbarLinks)).map(link => {
  delete link.bgColor
  return link
})

const Home = () => (
  <StyledLayout colorful={false} navBarLinks={homeNavbarLinks}>
    <SEO title="Home" />
    <Background />
    <Logo />
    <RainbowButton to={'/donations/'}><strong>Dona il tuo 5x1000!</strong></RainbowButton>
    <Quote full={fullIntro}>
      <strong>Andrea</strong> è il coraggiosissimo amico, che oggi è diventato
      il nostro angelo custode, per questo abbiamo scelto di chiamarci “Amici di
      Andrea”, un’associazione benefica che cerca di aiutare quei pazienti che
      vivono ogni giorno la sua stessa situazione.
    </Quote>
    <SubscribeNewsletter />
    <RainbowButton to={'/donations/'}>Fai una donazione!</RainbowButton>
  </StyledLayout>
)

export default Home
