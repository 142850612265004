import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import quotes from '../images/quotes.svg'

const StyledBigQuote = styled.div`
  margin: 1rem auto 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 45em;
`

const StyledSmallQuote = styled.img`
  width: 2em;
  flex-shrink: 0;
  padding: 0.5em;
`

StyledSmallQuote.defaultProps = {
  src: quotes,
  alt: 'big quote character'
}

const StyledLeftQuote = styled(StyledSmallQuote)`
  transform: rotate(180deg);
  align-self: flex-start;
`

const StyledRightQuote = styled(StyledSmallQuote)`
  transform: rotate(0deg);
  align-self: flex-end;
`

const visibilityTransitions = css`
  transition: height 1000ms, opacity 1000ms 0ms;
  /* transition: ease-in-out 1s; */
`

const visibleClass = css`
  ${visibilityTransitions}
  opacity: 1;
  visibility: visible;
`

const invisibleClass = css`
  ${visibilityTransitions}
  opacity: 0;
  height: 0;
  visibility: hidden;
`

const StyledTagline = styled.p`
  ${props => (props.visible ? visibleClass : invisibleClass)}
  color: white;
  text-align: center;
  text-emphasis: none;
  vertical-align: middle;
  font-family: serif;
  font-style: italic;
  font-size: 120%;
  padding: 0;
  margin: 0;
`

const ReadMore = styled.button`
  /* Reset Button CSS */
  background: none;
  border: none;
  /* Text style */
  font-family: inherit;
  font-size: inherit;
  font-style: inherit;
  color: inherit;
  /* Stuff */
  font-size: 70%;
  font-style: normal;
  font-family: sans-serif;
  border: solid;
  border-color: white;
  border-width: 0.2em;
  border-radius: 1000px;
  padding: 0.3em 0.7em;
  margin: 0.5em;
`

const Container = styled.p`
  display: flex;
  flex-direction: column;
  place-content: stretch;
  place-items: stretch;
  height: auto;
  width: auto;
  overflow: hidden;
`

const Quote = ({ full, children }) => {
  const [short, showShort] = useState(true)
  const toggleShort = () => showShort(!short)
  return (
    <StyledBigQuote>
      <StyledLeftQuote />
      <Container>
        <StyledTagline onClick={toggleShort} visible={short}>
          {children}
          <br />
          <ReadMore>Leggi di più...</ReadMore>
        </StyledTagline>
        <StyledTagline onClick={toggleShort} visible={!short}>
          {full}
          <br />
          <ReadMore>Chiudi</ReadMore>
        </StyledTagline>
      </Container>
      <StyledRightQuote />
    </StyledBigQuote>
  )
}

export default Quote
