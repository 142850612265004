import React from 'react'
import style from 'styled-components'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

const StyledDiv = style.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  object-fit: cover;
  object-position: 50% 50%;
`

const StyledImg = style(Img)`
  height: 100%;
`

const Background = () => (
  <StaticQuery
    query={graphql`
      query {
        placeholderImage: file(relativePath: { eq: "background.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 3840) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => (
      <StyledDiv>
        <StyledImg fluid={data.placeholderImage.childImageSharp.fluid} />
      </StyledDiv>
    )}
  />
)
export default Background
