import React from 'react'
import style from 'styled-components'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

const StyledLogo = style.div`
  width: 100%;
  max-width: 40em;
`

const StyledLogoImg = style(Img)`
  width: 100%;
`

const Logo = () => (
  <StaticQuery
    query={graphql`
      query {
        placeholderImage: file(relativePath: { eq: "logo.png" }) {
          childImageSharp {
            fluid(maxWidth: 1200) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    `}
    render={data => (
      <StyledLogo>
        <StyledLogoImg fluid={data.placeholderImage.childImageSharp.fluid} />
      </StyledLogo>
    )}
  />
)

export default Logo
